<template>

    <div v-if="view === 'mobile'" class="h-24 w-full mt-2 float-left">
        <div class="h-full w-full rounded-md bg-box-40 flex flex-col px-2 shadow-md">
            <div class="h-6 flex flex-row justify-end items-center ">
                <span class="text-xxs text-font-gray">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
                <i v-show="data.link && isValid(data.link)" class="mdi mdi-open-in-new text-font-gray pl-2 cursor-pointer text-xs" @click="goLink(data.link)"></i>
            </div>
            <div class="flex-1 flex flex-col justify-center items-start px-2 overflow-hidden">
                <span class="text-xs font-semibold truncate-2" style="color: #B4BCCE">{{ data.title }}</span>
                <span class="text-xxs mt-2" style="color: #78849E"
                    v-html="data.project_name || 'No project name'">
                </span>
            </div>
            <div v-if="data.file" @click="openFile(data.file, 'file')" class="h-6 flex cursor-pointer flex-row justify-end items-center px-2">
                <span class="flex flex-direction-row space-x-3">
                    <span v-if="data.file && data.file.length > 0" class="font-semibold text-font-gray text-base cursor-pointer"
                          @click="openFile(data.file, 'file')" style="color: #78849E">
                        <i class="mdi text-font-gray mr-auto" :class="getIconByExtension(data.file) + ' ' + getClassByExtension(data.file)"></i>
                    </span>
                        <span v-if="data.link !== ''" class="font-semibold text-font-gray text-base cursor-pointer"
                              @click="goLink(data.link)" style="color: #78849E">
                            <i class="mdi mdi-open-in-new text-font-gray"></i>
                        </span>
                    </span>
            </div>
        </div>
    </div>
    <div v-else class="h-32 w-1/4 p-2 float-left">
        <div class="h-full w-full rounded-md bg-box-40 flex flex-col px-2 shadow-md">
            <div class="h-6 flex flex-row justify-end items-center ">
                <span class="m-3 text-xs text-font-gray">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
                <i v-show="data.link && isValid(data.link)" class="mdi mdi-open-in-new text-font-gray cursor-pointer" @click="goLink(data.link)"></i>
            </div>
            <div class="flex-1 flex flex-col justify-center items-start px-2 overflow-hidden">
                <span class="text-md font-semibold truncate-1" style="color: #B4BCCE">{{ data.title }}</span>
                <span class="text-xss mt-2" style="color: #78849E"
                    v-html="data.project_name || 'No project name'">
                </span>
            </div>
            <div class="h-8 flex flex-row justify-end cursor-pointer items-center px-2">
                 <span class="flex flex-direction-row space-x-3">
                    <span v-if="data.file && data.file.length > 0" class="font-semibold text-font-gray text-md cursor-pointer"
                         :class="getClassByExtension(data.file)" @click="openFile(data.file, 'file')">
                        <i class="mdi text-2xl" :class="getIconByExtension(data.file)"></i>
                    </span>
                    <span v-if="data.link !== ''" class="font-semibold text-font-gray text-md cursor-pointer"
                        @click="goLink(data.link)" style="color: #78849E">
                        <i class="mdi mdi-open-in-new text-2xl text-font-gray"></i>
                    </span>
                    </span>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['data', 'view'],
    methods: {
        getClassByExtension(file) {
            let result = ''
            result += this.$colorByExtensionWithoutBackground(file)
            return result
        },
        getIconByExtension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        openFile(file, type) { this.$openFile(file, type) },
        goLink(link) { this.$goLink(link) },
        isValid(link) { this.$is_url(link) }
    }
}
</script>